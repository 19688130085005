@import "../../../../_global/scss/bootstrap.scss";

.NavigationSegmentContainer {
    padding: .5rem 0 1rem;

    .NavigationSegmentContainerBox {
        display: flex;
        justify-content: center;

        .NavigationSegmentElementsGroup {
            display: grid;
            gap: 1px;
            background-color: $gray-200;
            border: 1px solid $gray-200;
            border-radius: $btn-border-radius;
            overflow: hidden;


            grid-template-columns: 1fr 1fr;

            @include media-breakpoint-up(lg) {
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }

            &.NavigationSegmentElementsGroupOne {
                grid-template-columns: 1fr;
            }

            &.NavigationSegmentElementsGroupTwo {
                grid-template-columns: 1fr 1fr;
            }

            &.NavigationSegmentElementsGroupThree {
                grid-template-columns: 1fr 1fr 1fr;
            }

            &.NavigationSegmentElementsGroupFive {
                
                :last-child {
                    grid-column: span 2;
                }

                @include media-breakpoint-up(lg) {
                    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                    :last-child {
                        grid-column: 5;
                    }
                }
            }

            .NavigationSegmentElement {
                @include button-size($btn-padding-y-sm, 2 * $btn-padding-x-sm, $btn-font-size-sm, $btn-border-radius-sm);
                @include button-outline-variant($primary);
                border: 0;
                border-radius: 0;
            }
        }
    }
}