@import '../../../../_global/scss/bootstrap.scss';

.avatar-container {

    /*border: 5px solid $secondary;*/

    /*border-radius: 100%;*/
    overflow: hidden;
    /*width: 150px;
    height: 150px;*/

    &.avatar-editable {
        cursor: pointer;

        .avatar-overlay {
            background-color: #000000;
            opacity: 0;
    
            position: absolute;
            top:0;
            right: 0;
            bottom: 0;
            left:0;
    
            border-radius: 100%;
    
            transition: all 250ms ease-in-out;
        }

        &:hover {
            .avatar-overlay {
                opacity: 0.55;
            }
        }
    }

    position: relative;
    
    .avatar-img {
        object-fit: "cover";
        max-width: 100%;
        max-height: 100%;
        border-radius: 50%;
        width: 500px;
    }
    
}