@font-face {
    font-family: 'Bai Jamjuree';
    src: url('../fonts/BaiJamjuree/BaiJamjuree-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Bai Jamjuree';
    src: url('../fonts/BaiJamjuree/BaiJamjuree-Italic.ttf');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Bai Jamjuree';
    src: url('../fonts/BaiJamjuree/BaiJamjuree-Light.ttf');
    font-weight: 300;
    font-style: normal;
}@font-face {
    font-family: 'Bai Jamjuree';
    src: url('../fonts/BaiJamjuree/BaiJamjuree-LightItalic.ttf');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'Bai Jamjuree';
    src: url('../fonts/BaiJamjuree/BaiJamjuree-ExtraLight.ttf');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'Bai Jamjuree';
    src: url('../fonts/BaiJamjuree/BaiJamjuree-ExtraLightItalic.ttf');
    font-weight: 200;
    font-style: italic;
}
@font-face {
    font-family: 'Bai Jamjuree';
    src: url('../fonts/BaiJamjuree/BaiJamjuree-Medium.ttf');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Bai Jamjuree';
    src: url('../fonts/BaiJamjuree/BaiJamjuree-MediumItalic.ttf');
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'Bai Jamjuree';
    src: url('../fonts/BaiJamjuree/BaiJamjuree-SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Bai Jamjuree';
    src: url('../fonts/BaiJamjuree/BaiJamjuree-SemiBoldItalic.ttf');
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: 'Bai Jamjuree';
    src: url('../fonts/BaiJamjuree/BaiJamjuree-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Bai Jamjuree';
    src: url('../fonts/BaiJamjuree/BaiJamjuree-BoldItalic.ttf');
    font-weight: 700;
    font-style: italic;
}


@font-face {
    font-family: Lato;
    src: url('../fonts/Lato/Lato-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: Lato;
    src: url('../fonts/Lato/Lato-Italic.ttf');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: Lato;
    src: url('../fonts/Lato/Lato-Light.ttf');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: Lato;
    src: url('../fonts/Lato/Lato-LightItalic.ttf');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: Lato;
    src: url('../fonts/Lato/Lato-Thin.ttf');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: Lato;
    src: url('../fonts/Lato/Lato-ThinItalic.ttf');
    font-weight: 100;
    font-style: italic;
}
@font-face {
    font-family: Lato;
    src: url('../fonts/Lato/Lato-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: Lato;
    src: url('../fonts/Lato/Lato-BoldItalic.ttf');
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: Lato;
    src: url('../fonts/Lato/Lato-Black.ttf');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: Lato;
    src: url('../fonts/Lato/Lato-BlackItalic.ttf');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: Caveat;
    src: url("../fonts/Caveat/static/Caveat-Regular.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: Caveat;
    src: url("../fonts/Caveat/static/Caveat-Medium.ttf");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: Caveat;
    src: url("../fonts/Caveat/static/Caveat-SemiBold.ttf");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: Caveat;
    src: url("../fonts/Caveat/static/Caveat-Bold.ttf");
    font-weight: 700;
    font-style: normal;
}
.header-stylized {
    -webkit-text-stroke: 2px var(--bs-dark);
    color: var(--bs-yellow);
    text-shadow: 2px 2px 0 var(--bs-dark);
    font-size: 350%;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: .35rem;
}

.ci-stylized {
    font-family: Caveat;
}