@import "../../../_global/scss/bootstrap.scss";

.badge {
    display: inline-block;
    text-align: center;
    padding: 0;//4px;
    background: $red;
    color: $white;
    border-radius: 50%;
    font-size: 3px;
    line-height: 6px;
    width: 6px;
    vertical-align: middle;
    transform: scale(2.75);
    margin: auto 8px;
    position: relative;
    left: 4px;
    top: -1px;
}