@mixin overlay-backdrop($zindex, $backdrop-bg, $backdrop-opacity) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $zindex;
    width: 100vw;
    height: 100vh;
    background-color: rgba($backdrop-bg, $backdrop-opacity);
    box-shadow: rgba($backdrop-bg, $backdrop-opacity) 0px 0px 150px inset;
    -webkit-backdrop-filter: blur(5px) saturate(0.5);
  
    // Fade for backdrop
    &.fade { background-color: rgba($backdrop-bg, 0); }
    &.show { background-color: rgba($backdrop-bg, $backdrop-opacity); }
}