@import "../../../../_global/scss/bootstrap.scss";

.DataViewTableGroup {
    -webkit-user-select: text;
    user-select: text;

    .DataViewTableHeader {
        display: flex;
        flex-direction: column;

        .DataViewTableHeaderRow {
            flex: 1;
            display: flex;
            gap: 1rem;

            .DataViewTableHeaderRowItem {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
            }

            .DataViewTableHeaderRowPagination {
                flex:1;
                font-size: 60%;
                color: $gray-500;
                text-align: right;
                padding: .5rem 0;
            }
        }

    }

    .DataViewTable {
        .DataViewTableRowHeader {
            display: flex;
            margin: .25rem 0;
            padding: .2rem .35rem;
            font-weight: 700;
            cursor:default;
            .DataViewTableRowHeaderItem {
                flex: 1;
            }
        }
    }

    // .DataViewTableFooter {
        
    // }
}

.DataViewTableRow {

    display: flex;
    cursor: pointer;
    margin: .25rem 0;
    padding: .2rem .35rem;
    border-radius: .5rem;

    background-color: rgba($light, 0.35);
    &:nth-child(2n) {
        background-color: rgba($light, 0.75);
    }

    &:hover {
        background-color: rgba($light, 1);
    }
    .DataViewTableRowItem {
        margin: auto;
        flex: 1;
    }



    .DataViewTableRowDataItem {
        flex: 1;
    }

}

.DataViewSortList {

    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;

    .DataViewSortListItem {
        button {
            display: block;
            border: none;
            background-color: #FFF;
            padding: .15rem .25rem;
            margin: 0 .25rem;
            color: $gray-500;
            font-size: 65%;
        }
        &.active button {
            font-weight: 700;
            border-bottom: 2px solid $gray-500;
        }
    }
}

.DataViewSearchForm {
    font-size: 65%;
    // border-bottom: rgba($gray-900, .25) 1px solid;
    display: flex;
    align-items: center;

    svg {
        margin-right: .5rem;
        position: relative;
        left: 0;
        top: 0px;
    }
    .DataViewSearchFormInput {
        border-width: 2px !important;
        border-color: $gray-500;
        // border: 1px solid $gray-700;
        // padding: 0;
        // width: 0%;
        min-width: 80px;
        max-width: 450px;
        &[value=""] {
            width: 0%;
            text-align: center;
        }
        &:not([value=""]) {
            width: 100%;
        }
        background-color: #FFF !important;
        border-radius: 0;
        padding: $input-padding-y-sm $input-padding-x-sm;
        transition: all 250ms ease-in-out;
        &:focus {
            background-color: rgba($primary, 10%) !important;
            outline: 0;
            box-shadow: none;
            width: 100%;
            text-align: left;
        }

    }
    .DataViewSearchFormButton {
        background-color: transparent;
        border: none;
        opacity: .3;
        &:hover {
            opacity: .5;
        }
    }

    &.show {
        // border-bottom-color: rgba($gray-900, .65);

        .DataViewSearchFormButton {
            opacity: 1;
        }
    }

}