@import "../../_global/scss/bootstrap.scss";

.html-container {
    & p, & ul, & ol {
        margin-bottom: 0;
    }
}

.ribbon {
    position: absolute;
    top: 24px;
    right: 40px;
    transform: translateX(50%) rotate(45deg);
    width: 200px;
}
.ribbon-straight {
    padding: 12px 12px 24px;
    min-width: 30px;
    min-height: 50px;
    clip-path: polygon(0 0, 0 100%, 50% 80%, 100% 100%, 100% 0);
}

.text-gradient {
    /*linear-gradient(45deg, rgb(207, 39, 78) 0%, rgb(207, 199, 60) 100%)*/
    background: -webkit-linear-gradient(215deg, $orange 0%, #ffe600 100%);
    background-clip: text;
    color: rgba(0,0,0,0);
    -webkit-text-fill-color: transparent;
    display: inline-block;
}

body {
    background-color: white !important;
}

.pre-line {
    white-space: pre-line;
}

.pointer:hover {
    cursor: pointer;
}

.border-bottom-only {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
}

.stroke-orange {
    stroke: $orange;
}
.bg-orange {
    background-color: $orange !important;
}
.text-orange {
    color: $orange;
}
.border-orange {
    border-left: solid 2px $orange;
}

.baustelle {
    background: linear-gradient(63deg, #fdd904 0, #fdd904 15%, #3c3c3c 15.1%, #3c3c3c 30%, #fdd904 30.1%, #fdd904 45%, #3c3c3c 45.1%, #3c3c3c 60%, #fdd904 60.1%, #fdd904 75%, #3c3c3c 75.1%, #3c3c3c 90%, #fdd904 90.1%);
}

// .form-control:focus::placeholder {
//     position: relative;
//     font-size: 80%;
//     top: -20px;
// }

// .form-control {
//     border-top: none !important;
//     border-left: none !important;
//     border-right: none !important;
//     background-color: #00000006 !important;
// }
// .form-control:disabled {
//     opacity: 0.5 !important;
// }
/*.form-control.is-valid, .form-control.is-invalid {
    background-color: #F9FDFF !important;
}*/
a .profileImage {
    transition: all 0.25s ease-in-out;
    border: solid transparent 4px;
}
a:hover .profileImage {
    border-color: #13a2cf;
    border-width: 1rem;
}

.page-container {

    &.view-desktop {
        grid-template-columns: [nav] minmax(280px, 1fr) [body] 6fr;
        grid-template-rows: auto;
        .nav-toggler {
            display: none;
        }

        .nav-main {
            font-size: 100%;
            grid-column: 1;
            grid-row: 1;
            position: relative;
            border-bottom: none;
            min-height: 100vh;
            background-color: #FFF;
            background: rgba(255,255,255,0.25);
            backdrop-filter: blur(15px) saturate(150%);
            .nav-container {
                flex-direction: column;
                padding: .25rem 2rem;
                justify-content: flex-start;
                position: sticky;
                top: 0;left:0;
                height: 100vh;
                .nav-container-left,
                .nav-container-right {
                    gap: 1.5rem;
                    // display: block;
                    width: 100%;
                    justify-content: center;
                }
                .nav-container-right {
                    flex-grow: 1;
                }
                .nav-toggler {
                    display: none;
                }

                .nav-toggler-section {
                    display: block;
                    width: 100%;
                    position: relative;
                    top: auto;
                    right: auto;
                    bottom: auto;
                    left: auto;
                    padding: 0;
                    font-size: 100%;
                    z-index: 1000;
                    &.expand {
                        left:auto;
                    }
                }
                
                .nav-container-page-title {
                    font-size: 1.25rem;
                    top: 1px;
                    display: none;
                }

                .nav-brand {
                    span.text-primary {
                        display: inline;
                    }
                    margin: 1rem 0;
                }

                .nav-toggler-section {
                    display: flex;

                    justify-content:space-between;
                    flex-direction: column;
                    height: 100%;
                }
            }
        }
        .site-content {
            grid-column: 2;
            grid-row: 1;

            .site-content-header {
                display: block;
            }
        }
    }
    // &.view-mobile-expanded {

    // }
    // &.view-mobile-hidden {
    //     // ignore this, because it is mobile first!
    // }



    display: grid;
    min-height: 100%;
    grid-template-rows: [nav] 60px [body] auto;
    grid-template-columns: 1fr;
    // grid-template-areas: "nav body";

    gap: 0;

    justify-items: stretch;
    align-items: stretch;
    justify-content: stretch;
    align-content: stretch;

    .nav-main {
        grid-column: 1;
        grid-row: 1;
        font-size: 75%;
        background-color: white;
        position: sticky;
        top: 0;
        left: 0;
        border-bottom: 0.5px solid rgba($black,0.25);

        .nav-container {
            /*overflow-y: scroll;
            overflow-x: hidden;
            */
            height: 100%;

            position: sticky;
            left:0;top:0;
            display: flex;

            justify-content: space-between;
            align-items: center;
            padding: .125rem 1rem;

            .nav-container-left,
            .nav-container-right {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 0.5rem;
            }
            .nav-container-right {
                justify-content: flex-end;
            }

            .nav-container-page-title {
                font-size: 0.9375rem;
                font-weight: 500;
                margin: 0;
                position: relative;
                top: 3px;
                font-family: Lato;
                text-transform: uppercase;
                letter-spacing: 2px;
                display: block;
            }

            .nav-brand {
                font-size: 200%;
                text-align: center;
                font-family: 'Caveat', Roboto, sans-serif;
                display: inline-block;
                padding: 0;
                margin: 0;

                color: $black;

                transition: all 500ms ease-in-out;

                &:hover {
                    color: tinted-color-contrast($black);
                    span {
                        color: tinted-color-contrast($black) !important;
                    }
                }
                span.text-primary {
                    display: none;
                }
                span {
                    color: $red;
                    transition: all 500ms;
                }
            }

            .nav-toggler {
                display: inline-block;
                border-radius: .5rem;
                background: $primary;
                border: 1px solid $primary;
                color: white;
                margin: 0;
                padding: .25rem .5rem;
                svg {
                    position: relative;
                    top: -1px;
                }
            }

            .nav-toggler-section {
                display: flex;
                flex-direction: column;
                width: 100%;
                position: fixed;
                top: 60px;
                right: 0;
                bottom: 0;
                left: -100%;
                background-color: white;
                font-size: 150%;
                padding: 1rem 2rem;
                z-index: 10000;
                &.expand {
                    left:0;
                }
            }

            .nav-main-bar {
                margin: 0 0 1rem 0;
                list-style: none;
                margin: 0;
                padding: 0;
                flex-direction: column;
                width: 100%;
                font-size: 110%;
                .nav-item {
                    padding: 3.2px 2px;
                    margin: 0;// 1rem;
                    border: none;
                    border-bottom: 1px solid #efefef;
                    &:last-of-type {
                        border-bottom: none;
                    }
                    a {
                        padding: 0.5rem 0;//0.2rem;
                        display: block;
                        color: $gray-500;
                        &:hover {
                            color: $gray-700;
                        }
                        &.active>span {
                            color: $orange;
                            // color: rgba($orange, 0);
                            background: -webkit-linear-gradient(215deg, $orange 0%, $yellow 80%);
                            background-clip: text;
                            -webkit-text-fill-color: transparent;
                            display: inline-block;
                        }
                    }
                }
                .nav-heading {
                    margin: 2rem 0 1rem 0;
                    display: block;
                    width: 100%;
                    text-transform: uppercase;
                    font-size: .85rem;
                    color: $gray-400;
                }
                
            }
        }
    }

    .site-content {
        background-color: white;
        grid-column: 1;
        grid-row: 2;
        // overflow-y: scroll;
        padding: 1rem 2rem;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .site-content-header {
            h1 {
                font-weight: bold;
                font-size: 3rem;
                padding: 0;
                margin: 0;
                filter: drop-shadow(0 1px 0px rgba($gray-500, 0.75)); //drop-shadow(0 1px 0 rgba($gray-500,0.5))
            }
            padding: 1.5rem 0;
            margin: 0;
            display: none;
        }
        .site-content-body {
            .grid {

                display: grid;

                grid-template-columns: repeat( auto-fit ,   minmax(280px, 1fr)    ); // repeat(auto-fit, minmax(280px, 1fr));
                // grid-template-rows: 1fr;
    
                gap: 1rem;
        
            }
        }
        .site-content-footer {
            font-size: 80%;
            color: $gray-500;
            margin-top: auto;
            padding-top: 1rem;
        }
    }

}

.api-spinner::after {
    content: " ";
    border: 5px solid var(--bs-secondary);
    border-radius: 10rem;
    aspect-ratio: 1;
    width: 12.5%;
    display: block;
    text-align: center;
    margin: auto;
    border-top-color: transparent;
    animation: api-spin 4500ms ease-in-out infinite;
}

@keyframes api-spin {
    0% {
        transform: rotate(0turn) scale(0);
    }
    6.25% {
        transform: rotate(1turn) scale(1);
    }

    28.125% {
        transform: rotate(2turn) scale(1);
    }

    50% {
        transform: rotate(3turn) scale(1);
    }

    71.875% {
        transform: rotate(4turn) scale(1);
    }

    93.75% {
        transform: rotate(5turn) scale(1);
    }
    
    100% {
        transform: rotate(6turn) scale(0);
    }
}

.file-input-btn {
    display: none;
    &::after {
        display: block;
        content: "Klicken oder Droppen";

    }
    &::-webkit-file-upload-button {
        padding: $input-padding-y $input-padding-x;
        margin: (-$input-padding-y) (-$input-padding-x);
        margin-inline-end: $input-padding-x;
        color: $form-file-button-color;
        @include gradient-bg($form-file-button-bg);
        pointer-events: none;
        border-color: inherit;
        border-style: solid;
        border-width: 0;
        border-inline-end-width: $input-border-width;
        border-radius: 0; // stylelint-disable-line property-disallowed-list
        @include transition($btn-transition);
      }
}

.user-avatar-file-drop {
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: stretch;
    &.dropping {
        background-color: tint-color($primary, 80%); //rgba($primary, 0.1);
    }
}

.input-group .btn {
    z-index: 0;
}

.form-control {
    &:invalid, &.is-invalid,
    &:valid, &.is-valid {
        &:not(:focus) {
            border-color: $input-border-color;
        }
    }
}