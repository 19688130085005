
// .btn {
//     display: inline-block;
//     font-family: $btn-font-family;
//     font-weight: $btn-font-weight;
//     line-height: $btn-line-height;
//     color: $body-color;
//     text-align: center;
//     text-decoration: if($link-decoration == none, null, none);
//     white-space: $btn-white-space;
//     vertical-align: middle;
//     cursor: if($enable-button-pointers, pointer, null);
//     -moz-user-select: none;
//     -webkit-user-select: none;
//     user-select: none;
//     background-color: transparent;
//     border: $btn-border-width solid transparent;
//     @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-border-radius);
//     @include transition($btn-transition);
  
//     &:hover {
//       color: $body-color;
//       text-decoration: if($link-hover-decoration == underline, none, null);
//     }
  
//     .btn-check:focus + &,
//     &:focus {
//       outline: 0;
//       box-shadow: $btn-focus-box-shadow;
//     }
  
//     .btn-check:checked + &,
//     .btn-check:active + &,
//     &:active,
//     &.active {
//       @include box-shadow($btn-active-box-shadow);
  
//       &:focus {
//         @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
//       }
//     }
  
//     &:disabled,
//     &.disabled,
//     fieldset:disabled & {
//       pointer-events: none;
//       opacity: $btn-disabled-opacity;
//       @include box-shadow(none);
//     }
//   }
  
  

// @each $color, $value in $theme-colors {
//     .btn-#{$color} {
//         // $btn-color-contrast-dark: darken($color: $value, $amount: 50%);
//         $btn-color-contrast-light: lighten($color: $value, $amount: 60%);
//         $hover-background: if($color == $btn-color-contrast-light, shade-color($value, $btn-hover-bg-shade-amount), tint-color($value, $btn-hover-bg-tint-amount));
//         $active-background: if($color == $btn-color-contrast-light, shade-color($value,$btn-active-bg-shade-amount), tint-color($value, $btn-active-bg-tint-amount));

//         @include button-variant(
//             $value, $value, 
//             $color: if($color == "primary", $white, tinted-color-contrast($value)),
//             $hover-color: if($color == "primary", $white, tinted-color-contrast($hover-background)),
//             $active-color: if($color == "primary", $white, tinted-color-contrast($active-background)),
//             $disabled-color: if($color == "primary", $white, tinted-color-contrast($value))
//         );
//     }
// }
  
// @each $color, $value in $theme-colors {
//     .btn-outline-#{$color} {
//         background-color: $white;
//         border-color: $gray-200;
//         box-shadow: 0 2px 0 rgba($gray-800, 0.05),
//                     0 1px 0 rgba(#FFF, 0.5) inset;
//         color: $value;
//         // @include button-outline-variant(
//         //     $value,
//         //     $color-hover: if($color == "primary", $white, tinted-color-contrast($value)),
//         //     $active-color: if($color == "primary", $white, tinted-color-contrast($value))
//         // );
//     }
// }

@each $color, $value in $theme-colors {
    .btn-action-#{$color} {

        $btn-color-contrast-light: lighten($color: $value, $amount: 60%);
        $hover-background: if($color == $btn-color-contrast-light, shade-color($value, $btn-hover-bg-shade-amount), tint-color($value, $btn-hover-bg-tint-amount));
        $active-background: if($color == $btn-color-contrast-light, shade-color($value,$btn-active-bg-shade-amount), tint-color($value, $btn-active-bg-tint-amount));

        @include button-variant(
            $value, $value, 
            $color: if($color == "primary", $white, tinted-color-contrast($value)),
            $hover-color: if($color == "primary", $white, tinted-color-contrast($hover-background)),
            $active-color: if($color == "primary", $white, tinted-color-contrast($active-background)),
            $disabled-color: if($color == "primary", $white, tinted-color-contrast($value))
        );

        background: -webkit-linear-gradient(top, #eaf4f7 0%,#d0dadd 100%);
        border-color: $value;
        color: $value;
        font-size: 0.85rem;
        padding: .5rem .85rem;
        &:hover {
            color: $value;
            background-color: #d0dadd;
            box-shadow: 0 0 11px rgba($value, 0.5);
        }
    }
}

//   @mixin button-variant(
//   $background,
//   $border,
//   $color: color-contrast($background),
//   $hover-background: if($color == $color-contrast-light, shade-color($background, $btn-hover-bg-shade-amount), tint-color($background, $btn-hover-bg-tint-amount)),
//   $hover-border: if($color == $color-contrast-light, shade-color($border, $btn-hover-border-shade-amount), tint-color($border, $btn-hover-border-tint-amount)),
//   $hover-color: color-contrast($hover-background),
//   $active-background: if($color == $color-contrast-light, shade-color($background,$btn-active-bg-shade-amount), tint-color($background, $btn-active-bg-tint-amount)),
//   $active-border: if($color == $color-contrast-light, shade-color($border, $btn-active-border-shade-amount), tint-color($border, $btn-active-border-tint-amount)),
//   $active-color: color-contrast($active-background),
//   $disabled-background: $background,
//   $disabled-border: $border,
//   $disabled-color: color-contrast($disabled-background)
// )

// @mixin button-outline-variant(
//   $color,
//   $color-hover: color-contrast($color),
//   $active-background: $color,
//   $active-border: $color,
//   $active-color: color-contrast($active-background)
// )