@import '../../_global/scss/bootstrap.scss';

.profile-carousel {
    display: grid;
    grid-template-rows: 1fr;
    gap: 16px;
    
    .profile-carousel-item {
        padding: 1rem;       
        background: rgba($light, 0);
        display: flex;
        flex-flow: column;
        align-content: center;
        border-radius: 1rem;
        transition: all 500ms;// cubic-bezier(0.175, 0.885, 0.32, 1.275);

        &:hover {
            scale: 1.0125;
            background: rgba($light, 1);
            box-shadow: 0 0 8px rgba($dark, 0.25);
            .profile-carousel-img {
                scale: 1.025;
                box-shadow: 0 0 25px rgba($dark, 0.5),
                            0 0 0 4px rgba($primary,1) inset;
            }
        }

        .profile-carousel-img {
            aspect-ratio: 1;
            transition: all 500ms;
            max-width: 230px;
        }

        .profile-carousel-description {
            text-align: center;
        }
        .profile-carousel-link {
            text-align: center;
            padding: .5rem 1rem;
            margin: 0;
        }
    }
}