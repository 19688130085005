// Components
@import "mixins/alert";
@import "mixins/backdrop";
@import "mixins/button";
// @import "mixins/caret";
// @import "mixins/pagination";
// @import "mixins/lists";
// @import "mixins/list-group";
// @import "mixins/forms";
// @import "mixins/table-variants";

.form-select {
    -webkit-appearance: none;
    appearance: none;
}
.form-check-input {
    -webkit-appearance: none;
    appearance: none;
    -webkit-color-adjust: exact;
    color-adjust: exact;
}
// .form-control.is-invalid {
//     background: rgba($danger, 0.05);
// }
// .form-control.is-valid {
//     background: rgba($success, 0.05);
// }

.table.table-grid {
    border-spacing: 0px 0.5rem;
    border-collapse: separate;

    tbody tr {
        border: none;
        th, td {
            border: none;
            text-align: left;
            vertical-align: middle;
            .table-row-title {
                display: inline-block;
                vertical-align: center;
                margin: 0;
            }
        }
        > :first-child {
            border-top-left-radius: 1rem;
            border-bottom-left-radius: 1rem;
        }
        > :last-child {
            border-bottom-right-radius: 1rem;
            border-top-right-radius: 1rem;
            text-align: right;
        }
    }
}