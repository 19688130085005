@import "../../_global/scss/bootstrap.scss";

.grid-card {
    transition: all 250ms ease-in-out;
    background-color: $white;
    margin: 0;
    padding: 1.5rem;
    border-radius: 1rem;

    position: relative;
    box-shadow: 0 0 5px rgba($secondary,0.5),
                0 0 0 1px rgba($secondary,0.25) inset;

    box-sizing: border-box;

    display: flex;
    flex-direction: column;

    .grid-card-header {
        text-transform: uppercase;
        color: $gray-500;
        margin-top: 1.25rem;
        text-align: center;
    }
    .grid-card-body {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 300%;
        font-weight: bolder;
        text-align: center;
        padding: 1rem 0px;
        margin: 0px;
        flex-direction: column;
        div {
            text-align: center;
            padding: 1rem 0px;
            margin: 0px;
            // flex-grow: 1;
            display: flex;
            // flex-direction: column;
            justify-content: center;
            
            .grid-card-ribbon {
                position: absolute;
                top: 0;
                right: 32px;
                width: 42px;
                padding: 0;
                svg {
                    filter: drop-shadow( 0px 0px 4px rgba($dark, 0.5))
                }
            }
            
        }
    }
    .grid-card-footer {
        margin: 0;
        padding: 0;
        text-align: center;
        .grid-card-footer-link {
            margin: 0;            
        }
        .grid-card-footer-text {

        }
    }

    &.grid-card-hover:hover {
        // background-color: tint-color($light, 45) !important;
        box-shadow: 0 0 25px rgba($secondary, 0.5),
                    0 0 0 4px rgba($primary,1) inset;
        border-color: rgba($primary,1);
        border-width: 4px;
    }
    &.grid-card-hover-scale:hover {
        scale: 1.025;
    }

    // &.error-critical {
    //     background-color: mix($white,$danger, $weight: 90); //tint-color($danger, 99);
        
    // box-shadow: 0 0 5px rgba($secondary,0.5),
    // 0 0 0 1px rgba($danger,0.25) inset;
    // color: mix($black, $danger, 50);
    //     .fancy-card-header {
    //         color: inherit;
    //     }
    // }

}