.btn-spin {
    --btn-spin-color: $fff;
    position: relative;
    &.spinning {

        .btn-spin-text {
            opacity: 0;
        }
    
        &::after {
            content: "";
            position: absolute;
            width: 16px;
            height: 16px;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            border: 4px solid transparent;
            border-top-color: var(--btn-spin-color);
            border-radius: 50%;
            animation: btn-spin-keyframes 1s ease infinite;
        }
    }
}


@keyframes btn-spin-keyframes {
    from {
        transform: rotate(0turn);
    }

    to {
        transform: rotate(1turn);
    }
}